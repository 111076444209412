import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const SkillNotTool = () => (
  <Layout>
    <SEO
      title="Why You Should Learn The Skill Not the Tool"
      description="The point is not to get too caught up in learning the tools when it’s the skills that will help you the most. Tools come and go whereas skills develop if you nurture them"
    />
    <h1>Why You Should Learn The Skill Not the Tool</h1>

    <p>The title is a bit misleading here but I hope it gets the point across. It’s not completely accurate because on your way to success, you will have to learn some tools. The point is not to get too caught up in learning the tools when it’s the skills that will help you the most. Tools come and go whereas skills develop if you nurture them.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/mC1SSQK.jpg"
        alt="learn the skill not the tool"
        description="floating 3d brain graphic on purple background with highlights at the top and left"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@fakurian"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Milad Fakurian
      </a>
      {" "} on Unsplash
    </p>

    <p>Let’s take web development for example. We’ve all heard about the multiple technology stacks and the tools & libraries that go into them. What makes each one so special and why do people choose them? They each have their own advantages and disadvantages I’m sure but I’m fairly confident that an expert with each could fit their stack to most client briefs.</p>
    <p>It must sound like I’m contradicting myself, why even look at other technologies when you can do most things with a single stack?</p>
    <p>There are multiple answers</p>

    <h2>More Opportunities</h2>
    <p>If your goal is to get a good job, then surely keeping your options open is the best way to go. What if you have 5 potential businesses that you want to go and work for then you do some research and figure out that only 1 uses the stack that you have spent the last 3 years polishing skills in. Now your options are narrowed and if you fail to get a position, what do you do?</p>

    <h2>No Restrictions</h2>
    <p>When you aren’t thinking in terms of how to fit a tool to a problem and instead thinking about how to find a solution for a problem, the chances of solving that problem effectively are far higher.</p>
    <p>You aren’t restricted and can properly assess the problem. Making notes of everything that is needed, then you can put some time into finding the best way to get it done. It wouldn’t be out of the question to even produce some early examples with different tools to try to uncover some issues you haven’t thought of. If you have the time and budget to do such a thing that is.</p>

    <h2>The Tool isn’t That Hard to Learn Anyway</h2>
    <p>I think one of the fears of not being tied to one stack is that a person will forget what they’ve learned and be a jack of all trades and a master of none. I believe there is no reason to learn everything there is to know about a certain tool. I think it’s important to get familiar with something and know where to look if you do have any questions.</p>
    <p>Once you’ve learned one thing, the theory of it will help you learn a similar tool. They each have their own advantages and disadvantages but when you take programming languages for example, there are a lot of things they do similar, where they vary is in syntax.</p>

    <h2>Speak Multiple Languages</h2>
    <p>You will be able to think and communicate in ways that cover all tools. Instead of being tied to the structures and naming conventions of one particular language. Yes, you will have to have an overall understanding of all the main languages but you won’t have to be an expert in each to get a good understanding.</p>
    <p>You will be far more likely to be able to lead a team of people and pass messages between each department because you understand.</p>

    <h2>Negative</h2>
    <p>There is a downside to not mastering a tool and having a good understanding of each instead. You could become a jack of all trades and a master of none. There will always be people who know more about the tool than you.</p>
    <p>I would argue that in some industries this isn’t such a problem. When it comes to leaders in business, the people who have a good enough grasp of all areas will do the best. Imagine being able to fully explain and being world class in the technical tools but not understanding finance or customer acquisition. You will always be a cog in the machine until you figure out other areas.</p>
    <p>Again, I wouldn’t worry too much because you can always bring in expertise to fill the gaps. The point in all this is that it always helps to be a well rounded individual. A good understanding of all the processes around you will not only help you become a better part of the team but it will also help you to see and solve problems you wouldn’t have been able to otherwise.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default SkillNotTool
